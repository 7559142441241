<template>
  <div>
    <div
      class="flex border-1.5 border rounded-lg text-sm"
    >
      <div class="flex w-full p-2">
        <div v-if="!collapsed" class="flex w-12 h-28 items-center px-2 justify-center">
          <div class="mb-4">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.7258 3.42634C17.1618 3.67084 19.1163 5.13634 19.5333 7.65034H18.1233L20.3988 11.0358L22.6758 7.65034H21.1053C20.6883 4.26484 18.1248 2.06434 14.8863 1.75084C14.4378 1.71484 14.0223 2.09884 14.0223 2.58784C14.0523 3.00784 14.3403 3.39034 14.7258 3.42634ZM11.2008 1.71484H3.28534C2.83684 1.71484 2.48434 2.09884 2.48434 2.58784V10.1283C2.48434 10.6173 2.83684 11.0013 3.28534 11.0013H11.2008C11.6493 11.0013 12.0018 10.6173 12.0018 10.1283V2.58784C12.0018 2.09884 11.6493 1.71484 11.2008 1.71484ZM9.66184 20.9163C6.93784 20.6718 4.85434 18.8568 4.75834 15.8538H6.26434L3.99034 12.4683L1.71484 15.8538H3.22084C3.28384 19.7298 6.00784 22.2423 9.50284 22.5918C9.95134 22.6263 10.3683 22.2423 10.3683 21.7533C10.3711 21.5521 10.3012 21.3567 10.1714 21.2029C10.0416 21.0492 9.86066 20.9474 9.66184 20.9163ZM21.1368 13.3398H13.2198C12.7698 13.3398 12.4188 13.7253 12.4188 14.2128V21.7893C12.4188 22.2768 12.7698 22.6608 13.2198 22.6608H21.1353C21.5838 22.6608 21.9348 22.2768 21.9348 21.7893V14.2128C21.9363 13.7253 21.5523 13.3398 21.1368 13.3398Z"
                fill="#282828"
              />
            </svg>
          </div>
        </div>
        <div class="flex flex-col w-full justify-center">
          <div class="flex justify-between items-baseline">
            <div class="flex text-blue-600 py-1">{{ getUserNameOrCheck }}</div>
            <div class="pr-2 text-gray-400 text-xs">{{ getDateAndTime() }}</div>
          </div>
          <div class="flex pb-1">
            {{ item?.modified_by || "System" }}
            <span class="px-1 text-gray-400">made transition</span>
          </div>
          <div class="flex flex-start gap-4 pt-2 items-center text-xs" :class="[ collapsed ? 'flex-col' : '']">
            <div class="flex justify-between items-baseline gap-2.5">
              <div
                class="flex h-7 px-2 rounded-lg text-white items-center font-medium"
                :style="getFromStatusColor"
              >
                {{ item?.from_status?.status_name || "" }}
              </div>
              <div class="self-center">
                <svg
                  class="w-4 h-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"
                  />
                </svg>
              </div>
              <div
                class="flex h-7 px-2 rounded-lg text-white items-center font-medium"
                :style="getToStatusColor"
              >
                {{ item?.to_status?.status_name || "" }}
              </div>
              </div>
            <div
              class="flex p-1 rounded-lg text-[#282828] font-medium"
              :class="[ collapsed ? 'flex-col' : '']"
              :style="getBgColor"
              v-if="item?.transition_elapsed_time"
            >
              <p>
                Time in source: 
                <!-- <span class="text-blue-600 pl-4">{{ getTimeInSource() }}</span> -->
                <span class="text-blue-600">{{ item?.transition_elapsed_time }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { mapGetters } from "vuex";
export default {
  name: "case-logs",
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    collapsed: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getCaseData", "getSelectedEntity"]),

    getFromStatusColor() {
      // style="background-color: rgb(5, 190, 249);
      return `background-color: ${this.item?.from_status?.status_colour || ""};`;
    },
    getToStatusColor() {
      // style="background-color: rgb(5, 190, 249);
      return `background-color: ${this.item?.to_status?.status_colour || ""};`;
    },
    getBgColor () {
      return `background-color: #F2F2F2;`;
    },
    cardVisible() {
      return (
        this.item?.user?.user_name &&
        this.item?.from_status?.name &&
        this.item?.to_status?.name &&
        this.item?.event_timekey
      );
    },
    getUserNameOrCheck() {
      return this.item?.check_name || this.getSelectedEntity?.entity_name ||  this.getCaseData?.case_id;
    },
  },
  mounted() {
    console.log("this.item", this.item);
  },
  methods: {
    getDateAndTime() {
      const date = this.item?.modified_at;
      const formatDate = format(new Date(date), "dd LLL, yyyy");
      const formatTime = format(new Date(date), "HH:m");
      return `${formatDate} at ${formatTime}`;
    },
  },
};
</script>
